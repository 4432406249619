<template>
  <div style="display:flex;justify-content:center;" :class="{ fullscreen: landing.sections.styling.Fullscreen }">
    <div :class="`device-container ${pageMode}`" :style="{ backgroundImage: `url(${landing.sections.styling.BackgroundImage})` }">
      <div class="device-container-inner" :style="deviceContainerInner">
        <div v-if="landing.sections.styling.BackgroundVideo" class="videoContainer">
          <iframe v-if="videoType === 'youtube'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <iframe v-else-if="videoType === 'vimeo'" :src="videoSource" height="100%" width="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          <video v-else :src="videoSource" autoplay muted loop></video>
        </div>
        <div class="landing-widget" :style="landingWidgetStyle">
          <div ref="graphicContainer" class="landing-container" :style="landingContainerStyle">
            <div class="contentContainer" :style="contentContainerStyle">
              <div class="section-container" :style="containerSectionStyle">
                <div v-if="landing.sections.logo.Show" class="logo-container" :style="logoContainerStyle">
                  <a :href="landing.sections.logo.Link">
                    <img
                      class="section-select"
                      :src="landing.sections.logo.Image"
                      alt="Logo"
                      :style="{
                        maxWidth: landing.sections.logo.MaxWidth + 'px',
                        width: '100%',
                        margin: '0 auto',
                      }"
                      @click="selectBlock('logo')"
                    />
                  </a>
                </div>
                <div>
                </div>
                <div :style="mainContainerStyle">
                  <template v-if="selectedTab === 'thanks' && !landing.sections['thanks'].IsRedirect">
                    <div :style="{
                      fontSize: `${landing.sections.thanks.FontSize}px`,
                      textAlign: 'center',
                      color: landing.sections.thanks.Color,
                      padding: `${landing.sections.thanks.Padding}px`,
                    }">
                      {{ landing.sections.thanks.Content }}
                    </div>

                      <div v-if="!landing.sections.thanks.isShareSocial" style="text-align:center;" @click="selectBlock('main')">
                        <a href="javascript:;" :style="actionButtonStyle">
                          {{ landing.sections.thanks.BackLabel }}
                        </a>
                      </div>
                      <div v-else style="text-align:center;" class="social-box">
                        <a href="#" class="mr-2">
                          <i class="uil uil-facebook"></i>
                        </a>
                        <a href="#">
                          <i class="uil uil-twitter"></i>
                        </a>
                      </div>
                  </template>
                  <template v-else>
                    <div
                      class="widget-block widget-content"
                    >
                      <div class="section-select" style="text-align:center;"
                        @click="selectBlock('main')">
                        <div :style="{
                          color: landing.sections.main.HeadingColor,
                          fontSize: `${landing.sections.main.FontSize}px`,
                          textAlign: `${landing.sections.main.Align}`,
                        }">
                          {{ landing.sections.main.Content }}
                        </div>
                      </div>
                    </div>
                    <span class="section-select" 
                      :style="{ 
                        display: 'block',
                        textAlign: `${landing.sections.main.Align}`,
                        color: landing.sections.main.DescriptionColor,
                        padding: `${landing.sections.main.DescriptionPadding}px`,
                        fontSize: `${landing.sections.main.DescriptionFontSize}px`,
                      }"
                      @click="selectBlock('main')">
                      {{ landing.sections.main.Description }}
                    </span>
                    <div class="inputsSection section-select" @click="selectBlock('inputs')">
                      <div v-if="landing.sections.inputs.ShowName" class="input-group" :style="inputGroupStyle">
                        <input type="text" :placeholder="landing.sections.inputs.NamePlaceholder" :style="inputStyle">
                      </div>
                      <div v-if="landing.sections.inputs.ShowBirthday" class="row">
                        <div class="col-12" :style="{ color: landing.sections.inputs.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                          {{ landing.sections.inputs.BirthdayLabel }}
                        </div>
                        <div class="col-6">
                          <div  class="input-group" :style="inputGroupStyle">
                            <select :style="inputStyle">
                              <option value="">Month</option>
                              <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div  class="input-group" :style="inputGroupStyle">
                            <select :style="inputStyle">
                              <option value="">Day</option>
                              <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div v-if="landing.sections.inputs.ShowAnniversary" class="row">
                        <div class="col-12" :style="{ color: landing.sections.inputs.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                          {{ landing.sections.inputs.AnniversaryLabel }}
                        </div>
                        <div class="col-6">
                          <div  class="input-group" :style="inputGroupStyle">
                            <select :style="inputStyle">
                              <option value="">Month</option>
                              <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-6">
                          <div  class="input-group" :style="inputGroupStyle">
                            <select :style="inputStyle">
                              <option value="">Day</option>
                              <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div v-if="landing.sections.inputs.ShowBusinessName" class="input-group" :style="inputGroupStyle">
                        <input type="text" :placeholder="landing.sections.inputs.BusinessNamePlaceholder" :style="inputStyle">
                      </div>
                      <div v-if="landing.sections.inputs.ShowWebsiteUrl" class="input-group" :style="inputGroupStyle">
                        <input type="text" :placeholder="landing.sections.inputs.WebsiteUrlPlaceholder" :style="inputStyle">
                      </div>
                      <div v-for="(item, key) in sectionCustomFields" :key="key">
                        <template v-if="item.ShowField">
                          <div v-if="item.Type === 2" class="row">
                            <div class="col-12" :style="{ color: item.LabelColor || '#FFFFFF', marginBottom: '10px' }">
                              {{ item.Placeholder }}
                            </div>
                            <div class="col-6">
                              <div  class="input-group" :style="inputGroupStyle">
                                <select :style="inputStyle">
                                  <option value="">Month</option>
                                  <option v-for="index in 12" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                </select>
                              </div>
                            </div>
                            <div class="col-6">
                              <div  class="input-group" :style="inputGroupStyle">
                                <select :style="inputStyle">
                                  <option value="">Day</option>
                                  <option v-for="index in 31" :key="index" :value="`${index <= 9 ? 0 : ''}${index}`">{{ ("00" + index).slice(-2) }}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div v-else class="input-group" :style="inputGroupStyle">
                            <input type="text" :placeholder="item.Placeholder" :style="inputStyle">
                          </div>
                        </template>
                      </div>
                      <div v-if="landing.sections.inputs.ShowEmail" class="input-group" :style="inputGroupStyle">
                        <input type="email" :placeholder="landing.sections.inputs.EmailPlaceholder" :style="inputStyle">
                      </div>
                      <div v-if="landing.sections.inputs.ShowPhone">
                        <PhoneInput :placeholder="landing.sections.inputs.PhonePlaceholder" />
                      </div>
                    </div>
                    <div
                      class="widget-block widget-content section-select"
                      @click="selectBlock(pageMode === 'preview' ? 'thanks' : 'actionButton')"
                    >
                      <div style="text-align:center;">
                        <a href="javascript:;" :style="actionButtonStyle">
                          {{ landing.sections.actionButton.Label }}
                        </a>
                        <div class="disclaimer-section">
                          <b-form-checkbox v-if="landing.sections.actionButton.ShowDisclaimerCheckBox">
                            <span :style="actionDisclaimerStyle">{{ landing.sections.actionButton.Disclaimer | legal }}</span>
                          </b-form-checkbox>
                          <span v-else :style="actionDisclaimerStyle">{{ landing.sections.actionButton.Disclaimer | legal }}</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="user.business.agency.whitelabel.powered_enabled" class="text-center mt-2">
              <span class="powered-by-content" :style="{ color: landing.sections.legalFooter.Color }">powered by <a :href="user.business.agency.whitelabel.powered_url" target="_blank">{{ user.business.agency.whitelabel.powered_name }} </a></span>
            </div>
            <div class="legal-container section-select" :style="legalContainerStyle"
              @click="selectBlock('legalFooter')">
              <div>
                {{ landing.sections.legalFooter.Content }}
              </div>
              <div>
                <template v-if="landing.sections.legalFooter.PolicyLabel || landing.sections.legalFooter.ServiceLabel">
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.PolicyLabel }}
                  </a>
                  -
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.ServiceLabel }}
                  </a>
                </template>
                <template v-else>
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.LinkLabel }}
                  </a>
                </template>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    landing: {
      type: [Object],
      default: null,
    },
    pageMode: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: '',
    },
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    customFields() {
      return this.$store.getters["auth/customFields"];
    },

    sectionCustomFields() {
      if (!this.landing.sections.inputs.CustomFields) {
        // eslint-disable-next-line
        this.landing.sections.inputs.CustomFields = []
      }
      const customFieldIds = this.customFields.flatMap(item => item.id)
      return this.landing.sections.inputs.CustomFields.filter(item => customFieldIds.includes(item.Id))
        .sort((a, b) => a.id < b.id ? 1 : -1 )
    },

    subheadingContent() {
      let content = ''

      switch (this.device) {
        case 'desktop':
          content = this.landing.sections.subheading.Content
          break
        case 'mobile':
          content = this.landing.sections.subheading.MobileContent
          break
      }
      if (content)
        content = content
          .replace(/<keyword>/, `"${this.landing.keyword}"`)
          .replace(/<phone number>/, this.user.business.phone_number)
      return content
    },

    deviceContainerInner() {
      return {
        backgroundColor: this.landing.sections.styling.BackgroundColor,
      }
    },

    containerSectionStyle() {
      return {
        borderRadius: `${this.landing.sections.styling.Roudness}px`,
      }
    },

    logoContainerStyle() {
      return {
        textAlign: this.landing.sections.logo.Align,
        backgroundColor: this.landing.sections.logo.BackgroundColor,
        padding: `${this.landing.sections.logo.Padding}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    },

    legalContainerStyle() {
      return {
        margin: `10px 20px`,
        textAlign: this.landing.sections.legalFooter.Align,
        color: this.landing.sections.legalFooter.Color,
        fontSize: `${this.landing.sections.legalFooter.fontSize}px`,
      }
    },

    contentContainerStyle() {
      let style = {
        paddingBottom: '10px',
        overflow: 'hidden',
      }

      if (!this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        style.borderRadius = `${this.landing.sections.styling.Roudness}px`
        if (this.selectedTab === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      }

      return style
    },

    landingWidgetStyle() {
      if (this.landing.sections.styling.Fullscreen) {
        return {

        }
      }

      return  {
        padding: '30px 10px',
      }
    },

    landingContainerStyle() {
      let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.landing.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
      }

      if (this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.sections.main.BackgroundColor
        if (!this.landing.sections.logo.Show)
          style.justifyContent = 'center'
        if (this.selectedTab === 'thanks') {
          style.backgroundColor = this.landing.sections.thanks.BackgroundColor
        }
      } else {
        style.maxWidth = `${this.landing.sections.styling.MaxWidth}px`
      }

      return style
    },

    inputGroupStyle() {
      return {
        width: '100%',
        marginBottom: '15px',
      }
    },

    inputStyle() {
      return {
        width: '100%',
        padding: '12px 18px',
        fontSize: `${this.landing.sections.inputs.FontSize}px`,
        borderRadius: `${this.landing.sections.inputs.Roudness}px`,
        border: `1px solid ${this.landing.sections.inputs.BorderColor}`,
        backgroundColor: this.landing.sections.inputs.BackgroundColor,
        color: this.landing.sections.inputs.Color,
      }
    },

    mainContainerStyle() {
      if (!this.landing.sections.styling.Fullscreen) {
        return {
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: `${this.landing.sections.main.Padding}px`,
        }
      } else {
        return {
          maxWidth: `${this.landing.sections.styling.MaxWidth}px`,
          padding: `${this.landing.sections.main.Padding}px`,
          paddingBottom: '0px',
          margin: '0 auto' 
        }
      }
    },

    actionButtonStyle() {
      return {
        backgroundColor: this.landing.sections.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.sections.actionButton.BorderColor || 'transparent',
        color: this.landing.sections.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.sections.actionButton.Roudness}px`,
        fontSize: `${this.landing.sections.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.sections.actionButton.Padding}px`,
        width: '100%',
      }
    },

    actionDisclaimerStyle() {
      return {
        color: this.landing.sections.actionButton.DisclaimerColor,
        fontSize: `${this.landing.sections.actionButton.DisclaimerFontSize}px`,
        display: 'block',
        width: '100%',
      }
    },

    videoType() {
      let link = this.landing.sections.styling.BackgroundVideo || ""
      if (link.match(/\/\/www\.youtube\.com/)) {
        return 'youtube'
      } else if(link.match(/\/\/vimeo.com/)) {
        return 'vimeo'
      }

      return 'video'
    },

    videoSource() {
      let link = this.landing.sections.styling.BackgroundVideo || ""

      if (this.videoType === 'youtube') {
        let videoId = link.replace(/.*\?v=/, '').replace(/&.*/, '')
        return `https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1`
      } else if(this.videoType === 'vimeo'){
        let videoId = link.replace(/.*com\//, '')
        return `https://player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;muted=1&amp;background=1`
      }

      return link.replace(/(https|http)/, 'https')
    },
  },

  watch: {
    landing: {
      handler(val, oldVal) {
        this.addCustomStyle()
      },
      deep: true,
    }
  },

  mounted() {
    this.addCustomStyle()
  },

  methods: {
    selectBlock(block) {
      this.$emit('select-block', block)
    },

    getExportElement() {
      return this.$refs.graphicContainer
    },

    addCustomStyle() {
      document.getElementById('landing-css') && document.getElementById('landing-css').remove()

      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'landing-css'
      style.innerHTML = `
        .landing-container .vti__dropdown {
          background-color: ${this.landing.sections.inputs.BackgroundColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
        }
        .landing-container .vti__input {
          padding: 12px 20px 12px 5px;
          height: initial;
          font-size: ${this.landing.sections.inputs.FontSize}px;
          color: ${this.landing.sections.inputs.Color};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }
        .landing-container .vue-tel-input {
          border-color: ${this.landing.sections.inputs.BorderColor};
          border-radius: ${this.landing.sections.inputs.Roudness}px;
          background-color: ${this.landing.sections.inputs.BackgroundColor};
        }`
      document.querySelector('head').append(style)
    }
  },
}
</script>

<style lang="scss">
.disclaimer-section {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  text-wrap: balance;
  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before, .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>

<style lang="scss" scoped>
.social-box {
  
  a {
    font-size: 50px;
  }
  .uil-facebook {
    color: #4267B2;
  }
  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}

.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  &.design {
    min-width: 90%;
  }
  &:before {
    content: " ";
    border: 2px solid #d9dfe6;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -9px;
    left: -10px;
    border-radius: 36px;
    z-index: -1;
  }
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  video, iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.section-select {
  cursor: pointer;
  border: 1px solid transparent;
}
.device-container.design .section-select:hover {
  border-color: #4284f4;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.powered-by-content {
  padding-right: 10px;
  display: block;
  a {
    color: inherit;
    font-weight: bold;
    &:hover {
      text-decoration: underline!important;
    };
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
